<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">รอบสต๊อกยาและเวชภัณฑ์</h4>
      </div>
      <v-card-title class="pt-0 px-0">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="3">
            <v-text-field
              v-model="filter_search"
              label="พิมพ์คำค้นหา"
              single-line
              hide-details
            >
              <v-icon
                slot="prepend"
                color="#182839"
              >
                mdi-magnify
              </v-icon>
            </v-text-field>
          </v-col>
          <v-spacer/>
          <div class="d-flex align-end justify-end">
            <v-btn
              :loading="loader"
              :disabled="loader"
              color="blue-grey"
              class="my-3 white--text"
              @click="addItem"
            >
              <v-icon
                left
                dark
              >
                mdi-plus-circle
              </v-icon>
              เพิ่มรอบสต๊อกยาและเวชภัณฑ์
            </v-btn>
          </div>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="data"
        :search="filter_search"
        :loading="loaddata"
        loading-text="กำลังโหลด..."
      >
      <template v-slot:item="{ item }">
        <tr>
          <td class="text-center">{{item.product_stockcycle}}</td>
          <td class="text-center" style="margin-top: 10px !important;">{{item.product_stockcycle_date}}</td>

          <td class="d-flex justify-center">
            <v-switch
              v-model="item.product_status_bool"
              inset
              dense
            ></v-switch>
          </td>

          <td class="text-center">
            <v-btn
              icon
              @click="viewItem(item)"
            >
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="editItem(item)"
            >
              <v-icon>mdi-file-edit-outline</v-icon>
            </v-btn>
            <v-btn
              icon
              @click="deleteItem(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>

      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="fetchData"
        >
          รีเซ็ต
        </v-btn>
      </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="showDialog" max-width="500px">
      <v-card>
        <v-card-title>{{boolAddItems ? 'เพิ่มข้อมูลรอบสต๊อกยาและเวชภัณฑ์' : boolViewItems ? 'ข้อมูลรอบสต๊อกยาและเวชภัณฑ์' : boolEditItems ? 'แก้ไขข้อมูลรอบสต๊อกยาและเวชภัณฑ์' : 'ลบข้อมูลรอบสต๊อกยาและเวชภัณฑ์'}}</v-card-title>
        <v-card-text v-if="boolAddItems">
          <v-row class="mt-3 pa-0">
            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">รอบสต๊อก:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-text-field
                v-model="add_product_stockcycle_name"
                label="รอบสต๊อก"
                placeholder="ระบุรอบสต๊อก"
                disabled
                outlined
                single-line
                hide-details
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">วันที่รอบสต๊อก:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-menu
                ref="menuAddDate"
                v-model="menuAddDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedAddDateFormatted"
                    label="วันที่รอบสต๊อก"
                    placeholder="ระบุวันที่รอบสต๊อก"
                    outlined
                    single-line
                    hide-details
                    append-outer-icon="mdi-calendar-month"
                    readonly
                    @click:append-outer="menuAddDate = true"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="add_product_stockcycle_date"
                  no-title
                  @change="menuAddDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="boolViewItems">
          <v-row class="mt-3 pa-0">
            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">รอบสต๊อก:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{dataDialog.product_stockcycle}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">สถานะ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-switch
                v-model="dataDialog.product_status_bool"
                inset
                dense
                disabled
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="boolEditItems">
          <v-row class="mt-3 pa-0">
            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">รอบสต๊อก:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8">
              <span>{{dataDialog.product_stockcycle}}</span>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">วันที่รอบสต๊อก:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-menu
                ref="menuAddDate"
                v-model="menuAddDate"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="computedEditDateFormatted"
                    label="วันที่รอบสต๊อก"
                    placeholder="ระบุวันที่รอบสต๊อก"
                    outlined
                    single-line
                    hide-details
                    append-outer-icon="mdi-calendar-month"
                    readonly
                    @click:append-outer="menuAddDate = true"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="edit_product_stockcycle_date"
                  no-title
                  @change="menuAddDate = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4" class="d-flex align-center">
              <span class="label-drudsandmedical-add-stock-incard">สถานะ:</span>
            </v-col>
            <v-col cols="12" sm="12" md="8" lg="8" class="d-flex align-center">
              <v-switch
                v-model="dataDialog.product_status_bool"
                inset
                dense
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <span>{{`ต้องการลบรอบสต๊อก ${dataDialog.product_stockcycle || ''} นี้ใช่หรือไม่`}}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="!boolViewItems" color="error" text @click="closeDialog">
            <v-icon v-if="!boolDelItems" class="mr-3">mdi-close-circle</v-icon>
            ยกเลิก
          </v-btn>
          <v-btn
            :color="boolViewItems ? 'blue darken-1' : 'success'"
            text
            @click="submitDialog"
            :disabled="!cansave"
          >
            <v-icon v-if="!boolViewItems && !boolDelItems" class="mr-3">mdi-content-save</v-icon>
            {{boolViewItems ? 'ปิด' : boolAddItems ? 'เพิ่ม' : boolEditItems ? 'บันทึก' : 'ยืนยัน'}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import swal from 'sweetalert'
import moment from 'moment'

export default {
  data () {
    return {
      loader: null,
      loaddata: false,
      cansave: true,
      filter_search: '',
      showDialog: false,
      dataDialog: {},
      boolAddItems: false,
      boolViewItems: false,
      boolEditItems: false,
      boolDelItems: false,
      data: [],
      menuAddDate: false,
      add_product_stockcycle_name: '',
      add_product_stockcycle_date: new Date().toISOString().substr(0, 10),
      last_stock_cycle: '',
      edit_product_stockcycle_date: new Date().toISOString().substr(0, 10)
    }
  },
  computed: {
    headers () {
      return [
        {
          text: 'รอบสต๊อก',
          align: 'center',
          sortable: false,
          value: 'product_stockcycle'
        }, {
          text: 'รอบสต๊อกวันที่',
          align: 'center',
          sortable: false,
          value: 'product_stockcycle_date'
        }, {
          text: 'สถานะ',
          align: 'center',
          value: 'product_status',
          sortable: false
        }, {
          text: 'จัดการ',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    },
    computedAddDateFormatted () {
      return this.add_product_stockcycle_date ? moment(this.add_product_stockcycle_date).format('DD/MM/YYYY') : ''
    },
    computedEditDateFormatted () {
      return this.edit_product_stockcycle_date ? moment(this.edit_product_stockcycle_date).format('DD/MM/YYYY') : ''
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    filterOnlyCapsText (value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        value.toString().toLocaleUpperCase().indexOf(search) !== -1
    },
    async fetchData () {
      const self = this
      self.loaddata = true
      var temp = []
      var tempStockName, lastStockName, lastStockNum, lastStockNumStr
      try {
        // console.log(self.$store.state)
        const res = await self.axios.get(process.env.VUE_APP_API + '/stock/round', {
          headers: {
            Authorization: localStorage.getItem('Token') || self.$store.state.token
          }
        })
        if (res.status === 200) {
          res.data.result.data.map(el => {
            temp.push({
              _id: el._id,
              product_stockcycle: el.round_code,
              product_stockcycle_date: moment(el.date).add('year', 543).format('DD/MM/YYYY'),
              product_status: el.active ? 'active' : 'inactive',
              product_status_bool: el.active,
              created_at: el.create_at
            })
          })
          temp.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
          tempStockName = temp[temp.length - 1]?.product_stockcycle?.replace('L', '') || ''
          lastStockNum = Number(tempStockName) + 1
          lastStockNumStr = lastStockNum.toString()
          self.last_stock_cycle = lastStockNum
        }

        var word = ''
        for (word = ''; word.length < (5 - lastStockNumStr.length); word += '0') {}
        lastStockName = word + lastStockNumStr
      } catch (error) {
        console.log(error.message)
      } finally {
        self.data = temp
        self.loaddata = false
        self.add_product_stockcycle_name = `L${lastStockName}`
      }
    },
    addItem () {
      const self = this
      self.fetchData()
      self.boolAddItems = true
      self.showDialog = true
    },
    viewItem (item) {
      const self = this
      self.dataDialog = item
      self.boolViewItems = true
      self.showDialog = true
    },
    editItem (item) {
      const self = this
      var temp = ''
      var longtemp = ''
      self.dataDialog = item
      temp = item.product_stockcycle_date.split('/')
      longtemp = `${temp[2]}-${temp[1]}-${temp[0]}`
      self.edit_product_stockcycle_date = longtemp
      self.boolEditItems = true
      self.showDialog = true
    },
    deleteItem (item) {
      const self = this
      self.dataDialog = item
      self.boolDelItems = true
      self.showDialog = true
    },
    closeDialog () {
      const self = this
      self.boolAddItems = false
      self.boolViewItems = false
      self.boolEditItems = false
      self.boolDelItems = false
      self.showDialog = false
      self.dataDialog = {}
      self.fetchData()
      self.cansave = true
    },
    async submitDialog () {
      const self = this
      self.loaddata = true
      if (self.boolAddItems) {
        const obj = {
          round_code: self.add_product_stockcycle_name,
          date: self.add_product_stockcycle_date
        }
        try {
          const res = await self.axios.post(process.env.VUE_APP_API + '/stock/round', obj, {
            headers: {
              Authorization: localStorage.getItem('Token') || self.$store.state.token
            }
          })
          if (res.status === 200) {
            self.closeDialog()
            swal('สำเร็จ', 'เพิ่มรอบสต๊อกยาและเวชภัณฑ์สำเร็จ', 'success', {
              button: false,
              timer: 2000
            })
          } else {
            swal('ผิดพลาด', 'เพิ่มรอบสต๊อกยาและเวชภัณฑ์ไม่สำเร็จ กรุณาลองอีกครั้ง', 'error', {
              button: false,
              timer: 3000
            })
          }
        } catch (error) {
          console.log(error.message)
        }
      } else if (self.boolEditItems) {
        try {
          const obj = {
            round_code: self.dataDialog.product_stockcycle,
            date: self.dataDialog.product_stockcycle_date
          }
          self.cansave = false
          const res = await self.axios.put(`${process.env.VUE_APP_API}/stock/round/${self.dataDialog._id}`, obj, {
            headers: {
              Authorization: localStorage.getItem('Token') || self.$store.state.token
            }
          })
          if (res.status === 200) {
            swal('สำเร็จ', 'แก้ไขรอบสต๊อกยาและเวชภัณฑ์สำเร็จ', 'success', {
              button: false,
              timer: 2000
            })
          } else {
            swal('ผิดพลาด', 'แก้ไขรอบสต๊อกยาและเวชภัณฑ์ไม่สำเร็จ กรุณาลองอีกครั้ง', 'error', {
              button: false,
              timer: 3000
            })
          }
        } catch (error) {
          swal('ผิดพลาด', 'แก้ไขรอบสต๊อกยาและเวชภัณฑ์ไม่สำเร็จ กรุณาลองอีกครั้ง', 'error', {
            button: false,
            timer: 3000
          })
        } finally {
          self.cansave = true
          self.closeDialog()
        }
      } else if (self.boolDelItems) {
        try {
          self.cansave = false
          const res = await self.axios.delete(`${process.env.VUE_APP_API}/stock/round/${self.dataDialog._id}`, {
            headers: {
              Authorization: localStorage.getItem('Token') || self.$store.state.token
            }
          })
          if (res.status === 200) {
            swal('สำเร็จ', 'ลบรอบสต๊อกยาและเวชภัณฑ์สำเร็จ', 'success', {
              button: false,
              timer: 2000
            })
          } else {
            swal('ผิดพลาด', 'ลบรอบสต๊อกยาและเวชภัณฑ์ไม่สำเร็จ กรุณาลองอีกครั้ง', 'error', {
              button: false,
              timer: 3000
            })
          }
        } catch (error) {
          swal('ผิดพลาด', 'ลบรอบสต๊อกยาและเวชภัณฑ์ไม่สำเร็จ กรุณาลองอีกครั้ง', 'error', {
            button: false,
            timer: 3000
          })
        } finally {
          self.cansave = true
          self.closeDialog()
        }
      } else {
        self.closeDialog()
      }
    }
  }
}
</script>
